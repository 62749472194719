import { emptyApi as api } from "./emptyApi";
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import service from "../../utils/AccessTokenService";
import _ from "lodash";
import { SenderType, currentCollectionIdSelector } from "../GeneralSlice";
import { CollectionIdArgs } from "./collections";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { sleep } from "utils/Utils";
import { ThreadWidgetsActionArgs } from "./widgets/threadWidgets";

const threadsRtkApi = api.enhanceEndpoints({ addTagTypes: ["threads"] }).injectEndpoints({
    endpoints: (build) => ({
        getThreads: build.query<ThreadDescription[], undefined>({
            query: () => ({
                url: `/threads`
            }),
            providesTags: [{ type: "threads", id: "LIST" }]
        }),
        getThreadsByCollection: build.query<ThreadDescription[], CollectionIdArgs>({
            query: (args) => ({
                url: `/threads/by-collection/${args.collection_id}`
            }),
            transformResponse: (response: any[]) => {
                response.forEach((t) => {
                    t["thread_created"] = t["thread-created"];
                    t["last_message"] = t["last-message"];
                    delete t["thread-created"];
                    delete t["last-message"];
                    t.last_interaction = t.last_message || t.thread_created;
                });
                return response;
            },
            providesTags: [{ type: "threads", id: "LIST" }]
        }),
        getThreadFromUuid: build.query<ThradInfo, ThreadIdArgs>({
            query: (queryArg) => {
                return {
                    url: `/threads/${queryArg.thread_uuid}`
                };
            },
            providesTags: (result, error, arg) => [{ type: "history", id: arg.thread_uuid }]
        }),
        newThread: build.mutation<NewThreadResult, NewThreadArgs>({
            query: (args) => {
                return {
                    url: `/threads`,
                    method: "POST",
                    params: args.params,
                    body: args.body
                };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                const tmpUuid = "tmp-" + Math.random();
                const patchResult = dispatch(
                    threadsRtkApi.util.updateQueryData("getThreads", undefined, (draft: any[] | undefined) => {
                        if (draft) {
                            draft.push({ title: args.params.title, uuid: tmpUuid });
                        }
                    })
                );
                queryFulfilled.catch(patchResult.undo);
            },
            invalidatesTags: [{ type: "threads", id: "LIST" }]
        }),
        updateThreadTitle: build.mutation<any, UpdateThreadTitleArgs>({
            query: (queryArg) => {
                return {
                    url: `/threads/${queryArg.uuid}/title`,
                    method: "POST",
                    params: {
                        title: queryArg.title
                    }
                };
            },
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    threadsRtkApi.util.updateQueryData("getThreadFromUuid", { thread_uuid: arg.uuid }, (draft) => {
                        draft.title = arg.title;
                    })
                );
                queryFulfilled.catch(patchResult.undo);
            },
            invalidatesTags: [{ type: "threads", id: "LIST" }]
        }),
        suggestThreadTitle: build.query<string, SuggestThreadTitleArgs>({
            query: (queryArg) => {
                return {
                    url: `/threads/${queryArg.thread_uuid}/title-suggestion`,
                    params: { current_org_uuid: queryArg.current_org_uuid }
                };
            }
        }),
        deleteThread: build.mutation<any, DeleteThreadArgs>({
            query: (queryArg) => {
                return {
                    url: `/threads/${queryArg.uuid}`,
                    method: "DELETE"
                };
            },
            onQueryStarted: (arg, { dispatch, queryFulfilled, getState }) => {
                const collId = currentCollectionIdSelector(getState());
                const patchResult = dispatch(
                    threadsRtkApi.util.updateQueryData("getThreadsByCollection", { collection_id: collId }, (draft: any[] | undefined) => {
                        if (draft) {
                            return draft.filter((el) => el.uuid !== arg.uuid);
                        }
                    })
                );
                queryFulfilled.catch(patchResult.undo);
            },
            invalidatesTags: [{ type: "threads", id: "LIST" }]
        }),
        ask: build.mutation<any, AskArgs>({
            query: (queryArg) => {
                return {
                    url: `/threads/${queryArg.thread_uuid}/ask`,
                    method: "POST",
                    body: {
                        question: queryArg.payload.question,
                        active_index: "inputs",
                        use_filtering: false
                    }
                };
            },
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    threadsRtkApi.util.updateQueryData("getThreadFromUuid", { thread_uuid: arg.thread_uuid }, (draft) => {
                        draft.messages.push({ sender: "You", sender_type: "user", body: arg.payload.question });
                    })
                );
                try {
                    const result = await queryFulfilled;

                    dispatch(
                        threadsRtkApi.util.updateQueryData("getThreadFromUuid", { thread_uuid: arg.thread_uuid }, (draft) => {
                            // Update Query UUID
                            draft.messages[draft.messages.length - 1] = {
                                ...draft.messages[draft.messages.length - 1],
                                uuid: result?.data?.query_uuid,
                                thread_uuid: arg.thread_uuid
                            };

                            // Update Answer UUID && Sources!
                            draft.messages.push({
                                sender: "Vicuña",
                                sender_type: "llm",
                                body: result?.data?.answer,
                                uuid: result?.data?.answer_uuid,
                                thread_uuid: arg.thread_uuid,
                                cmetadata: {
                                    sources: result?.data?.sources
                                }
                            });
                        })
                    );
                } catch {
                    patchResult.undo();

                    /**
                     * Alternatively, on failure you can invalidate the corresponding cache tags
                     * to trigger a re-fetch:
                     * dispatch(api.util.invalidateTags(['Post']))
                     */
                }
            },
            invalidatesTags: [{ type: "threads", id: "LIST" }]
            // invalidatesTags: (result, error, arg) =>
            //     [{type: "threads", id: arg.uuid}],
            // invalidatesTags: ["files"]
        }),
        stream: build.mutation<any, AskArgs>({
            queryFn: async (args, api) =>
                readStream(
                    `/threads/${args.thread_uuid}/stream`,
                    {
                        current_org_uuid: args.current_org_uuid,
                        thread_uuid: args.thread_uuid,
                        question: args.payload.question
                    },
                    api.dispatch,
                    api.getState
                ),
            invalidatesTags: (result, error, arg) => [{ type: "history", id: arg.thread_uuid }]
        }),
        deleteMessage: build.mutation<any, DeleteMessageArgs>({
            query: (queryArg) => {
                return {
                    url: `/threads/${queryArg.thread_uuid}/${queryArg.message_uuid}`,
                    method: "DELETE"
                };
            },
            onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    threadsRtkApi.util.updateQueryData("getThreadFromUuid", { thread_uuid: arg.thread_uuid }, (draft: ThradInfo) => {
                        if (draft) {
                            const history = draft.messages.filter((el) => el.uuid !== arg.message_uuid);
                            return { ...draft, messages: history };
                        }
                    })
                );
                queryFulfilled.catch(patchResult.undo);
            },
            invalidatesTags: (result, error, arg) => [{ type: "history", id: arg.thread_uuid }]
        }),
        messageAction: build.mutation<any, ActionMessageArgs>({
            queryFn: (args, { dispatch, getState }, options, baseQuery) => {
                if (args.metadata?.initiates_stream === true) {
                    return readStream(
                        `/threads/${args.thread_uuid}/${args.message_uuid}/actions/${args.action_name}`,
                        {
                            current_org_uuid: args.params.current_org_uuid,
                            thread_uuid: args.thread_uuid,
                            question: args.metadata?.the_question ?? "..."
                            // todo: questo dovrebbe flippare i metadati come nell'else sotto
                        },
                        dispatch,
                        getState
                    );
                } else {
                    return baseQuery({
                        url: `/threads/${args.thread_uuid}/${args.message_uuid}/actions/${args.action_name}`,
                        method: "POST",
                        params: args.params,
                        body: args.body
                    });
                }
            },
            invalidatesTags: (result, error, arg) => [{ type: "history", id: arg.thread_uuid }]
        }),
        killThread: build.mutation<any, ThreadIdArgs>({
            query: (args) => ({
                url: `/threads/${args.thread_uuid}/kill`,
                method: "POST"
            })
        }),
        provideFeedback: build.mutation<any, ProvideFeedbackArgs>({
            query: (queryArg) => {
                return {
                    url: `/threads/${queryArg.thread_uuid}/${queryArg.message_uuid}/feedback`,
                    method: "POST",
                    params: {
                        feedback_value: queryArg.feedback_value,
                        feedback_string: queryArg.feedback_string
                    }
                };
            },
            invalidatesTags: ["feedbacks"],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    threadsRtkApi.util.updateQueryData("getThreadFromUuid", { thread_uuid: arg.thread_uuid }, (draft) => {
                        if (draft.messages) {
                            draft.messages.forEach((element: any) => {
                                if (element.uuid !== arg.message_uuid) return;
                                element.feedback_value = arg.feedback_value;
                                element.feedback_string = arg.feedback_string;
                            });
                        }
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            }
        }),
        copyAsTable: build.query<any, DeleteMessageArgs>({
            query: (args) => {
                return {
                    url: `/threads/${args.thread_uuid}/${args.message_uuid}/copy-as-table`
                };
            }
        }),
        archiveThread: build.mutation<any, ArchiveThreadArgs>({
            query: (args) => ({
                url: `/threads/${args.thread_uuid}/archive`,
                method: "POST",
                body: args.body
            }),
            invalidatesTags: (result, error, arg) => [{ type: "history", id: arg.thread_uuid }, { type: "threads", id: "LIST" }, "workingMemory"]
        }),
        cloneThread: build.mutation<any, CloneThreadArgs>({
            query: (args) => {
                return {
                    url: `/threads-clone`,
                    method: "POST",
                    params: args.params
                };
            },
            invalidatesTags: [{ type: "threads", id: "LIST" }]
        }),
        shareThread: build.mutation<any, ShareThreadArgs>({
            query: (args) => {
                return {
                    url: `/threads/${args.thread_uuid}/share-code`,
                    method: "GET",
                    params: args.params
                };
            }
        })
    }),
    overrideExisting: false
});

export default threadsRtkApi;

//*****************/
function readAttribute(input: string, name: string) {
    const idx = input.indexOf(name + "=");
    if (idx === -1) return 0;
    const start = input.indexOf("=", idx);
    const value = input.substring(start + 1, input.indexOf(" ", start));
    return value;
}

function countBackslashesFrom(input: string, idx: number) {
    let count = 0;
    for (let i = idx - 1; i >= 0; i--) {
        if (input[i] === "\\") count++;
        else break;
    }
    return count;
}

function parseMultipleJson(string: string) {
    let start = string.indexOf("{");
    let open = 0;
    let isString = false;
    const res = [];
    for (let i = start; i < string.length; i++) {
        if (!isString && string[i] === "{") {
            open++;
            if (open === 1) {
                start = i;
            }
        } else if (!isString && string[i] === "}") {
            open--;
            if (open === 0) {
                res.push(JSON.parse(string.substring(start, i + 1)));
                start = i + 1;
            }
        } else if (string[i] === '"' && (i < 2 || countBackslashesFrom(string, i) % 2 === 0)) {
            isString = !isString;
        }
    }
    return { jsonsInString: res, tail: open > 0 ? string.substring(start) : "" };
}

export function readJSON(reader: ReadableStreamDefaultReader) {
    return {
        async *[Symbol.asyncIterator]() {
            let readResult = await reader.read();
            let stringBuffer = "";
            while (!readResult.done) {
                //const string = String.fromCharCode(...readResult.value);

                const string = new Uint8Array(readResult.value).reduce((data, byte) => data + String.fromCharCode(byte), "");

                const { jsonsInString, tail } = parseMultipleJson(stringBuffer ? stringBuffer + string : string);
                stringBuffer = tail;
                yield jsonsInString;
                readResult = await reader.read();
            }
        }
    };
}

type StreamArgs = {
    current_org_uuid: string;
    thread_uuid: string;
    question: string;
};

type ReadStreamArgs = StreamArgs | ThreadWidgetsActionArgs;

// Type guard functions
function isStreamArgs(args: ReadStreamArgs): args is StreamArgs {
    return "thread_uuid" in args && "question" in args;
}

function isThreadWidgetsActionArgs(args: ReadStreamArgs): args is ThreadWidgetsActionArgs {
    return "actionName" in args && "params" in args && "body" in args;
}

export async function readStream(url: string, args: ReadStreamArgs, dispatch: ThunkDispatch<any, any, any>, getState: () => unknown) {
    const patchesToUndo = [];

    const threadUUID = isStreamArgs(args) ? args.thread_uuid : args.threadId;

    // mock user message only if it's StreamArgs
    if (isStreamArgs(args)) {
        const patchResult = dispatch(
            threadsRtkApi.util.updateQueryData("getThreadFromUuid", { thread_uuid: threadUUID }, (draft) => {
                draft.messages.push({
                    thread_uuid: threadUUID,
                    sender: "You",
                    sender_type: "user",
                    body: args.question
                });
            })
        );
        patchesToUndo.push(patchResult);
    }

    try {
        const patchResult = dispatch(
            threadsRtkApi.util.updateQueryData("getThreadFromUuid", { thread_uuid: threadUUID }, (draft) => {
                draft.messages.push({
                    thread_uuid: threadUUID,
                    sender: "Vicuña",
                    sender_type: "llm",
                    ended: false
                });
            })
        );
        patchesToUndo.push(patchResult);
        const state: any = getState();
        const collId = state.general.currentCollectionId;
        const token = await service.getAccessToken();

        let params = new URLSearchParams();
        let body = {};
        if (isStreamArgs(args)) {
            params = new URLSearchParams({ current_org_uuid: args.current_org_uuid });
            body = {
                question: args.question,
                // history: history ? history : [],
                // FIXME: Active index?
                active_index: collId,
                use_filtering: false
            };
        } else if (isThreadWidgetsActionArgs(args)) {
            params = new URLSearchParams(Object(args.params));
            body = args.body;
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}${url}?${params}`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });

        // response.body is a ReadableStream
        if (!response.ok) throw new Error(response.type);

        const reader = response.body.getReader();
        for await (const jsons of readJSON(reader)) {
            for (const json of jsons) {
                //console.log("RICEVUTO", json);

                const jsonQuestion = json["question"];
                const jsonAnswer = json["answer"];
                const jsonSources = json["sources"];
                const jsonRecommendations = json["recommendations"];
                const questionUuid = json["query_uuid"];
                const answerUuid = json["answer_uuid"];

                _.unset(json, "answer");
                _.unset(json, "sources");
                _.unset(json, "recommendations");
                _.unset(json, "query_uuid");
                _.unset(json, "answer_uuid");

                //console.log("ANSWER", jsonAnswer);

                const patchResult = dispatch(
                    threadsRtkApi.util.updateQueryData("getThreadFromUuid", { thread_uuid: threadUUID }, (draft) => {
                        if (jsonQuestion) {
                            const questionMessage = draft.messages[draft.messages.length - 2];
                            _.set(questionMessage, "body", jsonQuestion);
                        }
                        if (questionUuid) {
                            const questionMessage = draft.messages[draft.messages.length - 2];
                            _.set(questionMessage, "uuid", questionUuid);
                        }

                        const answerMessage = draft.messages[draft.messages.length - 1];
                        _.merge(answerMessage, json);
                        if (jsonAnswer && answerMessage) {
                            let new_body = answerMessage.body ? answerMessage.body + jsonAnswer : jsonAnswer;

                            const progIdx = new_body.lastIndexOf("<prog ");
                            if (progIdx !== -1) {
                                const closeIdx = new_body.indexOf("/>", progIdx);
                                const tag = new_body.substring(progIdx, closeIdx) + " />";

                                _.set(answerMessage, "progress", {
                                    value: Number(readAttribute(tag, "prog")),
                                    finished: readAttribute(tag, "end") === "true"
                                });

                                new_body = new_body.substring(closeIdx + 2);
                            }

                            //console.log(new_body);

                            const progIdxEM = new_body.lastIndexOf("<ENDMS/>");
                            if (progIdxEM !== -1) {
                                const closeIdx = new_body.indexOf("/>", progIdxEM);

                                _.set(answerMessage, "RELEASE", true);

                                // remove <ENDMS/> tag from body
                                new_body = new_body.substring(0, progIdxEM) + new_body.substring(closeIdx + 2);
                            }

                            // if <clear/> in newbody, remove everything before it
                            const clearIndex = new_body.lastIndexOf("<clear/>");
                            if (clearIndex !== -1) {
                                new_body = new_body.substring(clearIndex + 8);
                            }

                            _.set(answerMessage, "body", new_body);
                        }
                        if (answerUuid) {
                            _.set(answerMessage, "uuid", answerUuid);
                        }
                        if (jsonSources) {
                            _.merge(answerMessage, {
                                cmetadata: {
                                    sources: jsonSources
                                }
                            });
                        }
                        if (jsonRecommendations) {
                            _.merge(answerMessage, {
                                cmetadata: {
                                    recommendations: jsonRecommendations
                                }
                            });
                        }
                    })
                );
                patchesToUndo.push(patchResult);
            }
        }
        const streamEndedPatch = dispatch(
            threadsRtkApi.util.updateQueryData("getThreadFromUuid", { thread_uuid: threadUUID }, (draft) => {
                const answerMessage = draft.messages[draft.messages.length - 1];
                _.set(answerMessage, "ended", true);
            })
        );
        patchesToUndo.push(streamEndedPatch);
        return { data: {} };
    } catch (e) {
        console.log("STREAM ERROR", e);

        for (let i = patchesToUndo.length - 1; i >= 0; i--) {
            patchesToUndo[i].undo();
        }
        return { error: e.message };
    }
}

//*****************/
class SortedThreads {
    today: ThreadDescription[] = [];
    yesterday: ThreadDescription[] = [];
    lastWeek: ThreadDescription[] = [];
    older: ThreadDescription[] = [];
    archived: ThreadDescription[] = [];
    empty: boolean = true;
}

export function UseSortedThreads(collectionId: string) {
    const response = useGetThreadsByCollectionQuery({ collection_id: collectionId }, { skip: !collectionId });
    const st = useMemo(() => {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        const todayTime = today.getTime() / 1000;

        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        const yesterdayTime = yesterday.getTime() / 1000;

        const lastweek = new Date(today);
        lastweek.setDate(lastweek.getDate() - 7);
        const lastWeekTime = lastweek.getTime() / 1000;

        const threads = response.data ? [...response.data] : [];
        threads.sort((a, b) => b.last_interaction - a.last_interaction);
        const sorted: SortedThreads = new SortedThreads();
        sorted.empty = threads.length === 0;
        threads.forEach((t) => {
            if (t.status === "archived") sorted.archived.push(t);
            else if (t.last_interaction >= todayTime) sorted.today.push(t);
            else if (t.last_interaction >= yesterdayTime) sorted.yesterday.push(t);
            else if (t.last_interaction >= lastWeekTime) sorted.lastWeek.push(t);
            else sorted.older.push(t);
        });
        return sorted;
    }, [response.data]);

    return { ...response, data: st };
}

//*****************/
export type ThreadIdArgs = {
    thread_uuid: string;
};

export type SuggestThreadTitleArgs = ThreadIdArgs & {
    current_org_uuid: string;
};

export type ThreadDescription = {
    uuid: string;
    title: string;
    thread_created: number;
    last_message: number;
    last_interaction: number;
    status: string;
    local_memory: any;
};

export type MessageInfo = {
    uuid: string;
    thread_uuid: string;
    sender: string;
    sender_type: string;
    created: number;
    body: string;
    cmetadata: {
        sources: [];
    };
    feedback_value: null;
    feedback_string: null;
};

export type ThradInfo = ThreadDescription & {
    user_uuid: string;
    created: number;
    messages: any[];
    collection_uuid: string;
};

export type AskPayload = {
    question: string;
    history: Record<string, string>[];
};

export type NewThreadArgs = {
    params: {
        title: string;
        collection_uuid: string;
    };
    body?: any;
};

export type NewThreadResult = {
    uuid: string;
};

export type UpdateThreadTitleArgs = {
    uuid: string;
    title: string;
    return_not_authenticated?: boolean;
};

export type DeleteThreadArgs = {
    uuid: string;
};

export type AskArgs = {
    thread_uuid: string;
    current_org_uuid: string;
    payload: AskPayload;
};

export type DeleteMessageArgs = {
    thread_uuid: string;
    message_uuid: string;
};

export type ActionMessageArgs = {
    thread_uuid: string;
    message_uuid: string;
    action_name: string;
    params: { current_org_uuid: string };
    body: any;
    metadata: any;
};

export type ProvideFeedbackArgs = {
    thread_uuid: string;
    message_uuid: string;
    feedback_value: boolean;
    feedback_string: string;
    return_not_authenticated?: boolean;
};

export type ArchiveThreadArgs = ThreadIdArgs & {
    body?: {
        approved_content: string;
    };
};

export type CloneThreadArgs = {
    params: {
        share_code: string;
        collection_uuid: string;
    };
};

export type ShareThreadArgs = {
    thread_uuid: string;
    params?: {
        expiration?: number;
        limit_uses?: number;
    };
};

export const DEFAULT_TITLE = "Untitled";

export const useNewThreadCallback = () => {
    const navigate = useNavigate();
    const [trigger] = useNewThreadMutation();
    const [fetchThreads] = useLazyGetThreadsByCollectionQuery();
    return useCallback(
        async (collectionId: string, state?: { template?: string; send?: boolean }) => {
            const result = await trigger({
                params: { title: DEFAULT_TITLE, collection_uuid: collectionId }
            });
            if ("data" in result) {
                const newChatUUID = result.data?.uuid;
                if (!newChatUUID) return;

                let created = false;
                do {
                    await sleep(50);
                    const threads = await fetchThreads({ collection_id: collectionId }, false).unwrap();
                    created = threads.some((t) => t.uuid === newChatUUID);
                } while (!created);

                navigate("../" + newChatUUID, { state });
            } else {
                console.log("Error creating new thread", result);
                // Error!
            }
        },
        [fetchThreads, navigate, trigger]
    );
};

//********************************************
export const useIsArchivedThread = (threadId: string) => {
    return useGetThreadFromUuidQuery(
        { thread_uuid: threadId },
        {
            selectFromResult: ({ data }) => ({
                data: data?.status === "archived"
            })
        }
    );
};

export const useIsNotReadyThread = (threadId: string) => {
    return useGetThreadFromUuidQuery(
        { thread_uuid: threadId },
        {
            skip: !threadId,
            selectFromResult: ({ data }) => ({
                data: data?.status === "not_ready"
            })
        }
    );
};

export const useIsStreamEnded = (message: any) => {
    const isEnded = useMemo(
        () => message?.sender_type === SenderType.USER || !!message?.created || message?.ended === true,
        [message?.created, message?.ended, message?.sender_type]
    );
    return isEnded;
};

export const {
    useGetThreadFromUuidQuery,
    useGetThreadsByCollectionQuery,
    useLazyGetThreadsByCollectionQuery,
    useLazyGetThreadFromUuidQuery,
    useNewThreadMutation,
    useLazySuggestThreadTitleQuery,
    useUpdateThreadTitleMutation,
    useDeleteThreadMutation,
    useMessageActionMutation,
    useAskMutation,
    useStreamMutation,
    useDeleteMessageMutation,
    useKillThreadMutation,
    useProvideFeedbackMutation,
    useCopyAsTableQuery,
    useArchiveThreadMutation,
    useCloneThreadMutation,
    useShareThreadMutation
} = threadsRtkApi;

export class useLazyStreamQuery {}
