import { useEffect, useMemo, useState } from "react";
import { useLazyGetThreadsByCollectionQuery } from "state/api/threads";
import { useLazyGetCurrentUserOrganizationsQuery } from "state/api/organizations";
import { useLazyGetAllCollectionsQuery } from "state/api/collections";
import { setCurrentCollectionId, setCurrentOrganizationId, useCurrentCollectionId, useCurrentOrganizationId } from "state/GeneralSlice";
import { useDispatch } from "react-redux";

export function useAppConfiguration() {
    return process.env.REACT_APP_CONFIG;
}

export function useIsCucinelliSophia() {
    const config = useAppConfiguration();
    return config === "cucinelli_sophia";
}

//********************************************
export function getLastWord(text: string, caretPosition?: number) {
    const startIdx = text.lastIndexOf(" ", caretPosition) + 1;
    const serchIdx = Math.min(startIdx, text.length - 1);
    const endIdx = text.indexOf(" ", serchIdx);
    return text.substring(startIdx, endIdx >= 0 ? endIdx : text.length);
}

//********************************************
export function useUrl() {
    const fullUrl = window.location.href;

    const url = useMemo(() => {
        const slashIdx = fullUrl.indexOf("//");
        const startIdx = fullUrl.indexOf("/", slashIdx + 2);

        return {
            baseUrl: fullUrl.substring(0, startIdx),
            pathUrl: fullUrl.substring(startIdx)
        };
    }, [fullUrl]);

    return url;
}

export function useChatPath() {
    const orgId = useCurrentOrganizationId();
    const collId = useCurrentCollectionId();
    return `/chat/${orgId}/${collId}`;
}

export function useCheckWorkspace(ordId: string, collectionId: string) {
    const [state, setState] = useState({ loading: true, valid: false, message: "" });
    const [fetchOrganizations] = useLazyGetCurrentUserOrganizationsQuery();
    const [fetchCollections] = useLazyGetAllCollectionsQuery();

    useEffect(() => {
        const checkOrganization = async () => {
            if (!ordId) return true;
            const organizations = await fetchOrganizations(undefined, true).unwrap();
            if (!organizations) return false;
            for (let i = 0; i < organizations.length; i++) {
                if (organizations[i].uuid === ordId) return true;
            }
            return false;
        };

        const checkCollection = async () => {
            if (!collectionId) return true;
            const collections = await fetchCollections({ org: ordId }, true).unwrap();
            if (!collections) return false;
            for (let i = 0; i < collections.length; i++) {
                if (collections[i].uuid === collectionId) return true;
            }
            return false;
        };

        const check = async () => {
            if (!ordId || !collectionId) {
                setState({ loading: false, valid: true, message: "" });
                return;
            }

            const isOrganizationIdValid = await checkOrganization();
            if (!isOrganizationIdValid) {
                setState({ loading: false, valid: false, message: "Invalid Organization\nor not enough privileges" });
                return;
            }

            const isCollectionIdValid = await checkCollection();
            if (!isCollectionIdValid) {
                setState({ loading: false, valid: false, message: "Invalid Collection\nor not enough privileges" });
                return;
            }

            setState({ loading: false, valid: true, message: "" });
        };

        check();
    }, [collectionId, fetchCollections, fetchOrganizations, ordId]);

    return state;

}

export function useCheckAndSetWorkspace(orgId: string, collectionId: string, threadId: string) {
    const [state, setState] = useState({ loading: true, valid: false, message: "" });
    const [fetchOrganizations] = useLazyGetCurrentUserOrganizationsQuery();
    const [fetchCollections] = useLazyGetAllCollectionsQuery();
    const [fetchThreads] = useLazyGetThreadsByCollectionQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        const checkOrganization = async () => {
            if (!orgId) return true;
            const organizations = await fetchOrganizations(undefined, true).unwrap();
            if (!organizations) return false;
            for (let i = 0; i < organizations.length; i++) {
                if (organizations[i].uuid === orgId) return true;
            }
            return false;
        };

        const checkCollection = async () => {
            if (!collectionId) return true;
            const collections = await fetchCollections({ org: orgId }, true).unwrap();
            if (!collections) return false;
            for (let i = 0; i < collections.length; i++) {
                if (collections[i].uuid === collectionId) return true;
            }
            return false;
        };

        const checkThread = async () => {
            if (!threadId) return true;
            const threads = await fetchThreads({ collection_id: collectionId }, false).unwrap();
            console.log("threads", threads);
            if (!threads) return false;
            for (let i = 0; i < threads.length; i++) {
                if (threads[i].uuid === threadId) return true;
            }
            return false;
        };

        const check = async () => {
            if (!orgId || !collectionId || !threadId) {
                setState({ loading: false, valid: true, message: "" });
                return;
            }

            const isOrganizationIdValid = await checkOrganization();
            if (!isOrganizationIdValid) {
                setState({ loading: false, valid: false, message: "Invalid Organization\nor not enough privileges" });
                return;
            }

            const isCollectionIdValid = await checkCollection();
            if (!isCollectionIdValid) {
                setState({ loading: false, valid: false, message: "Invalid Collection\nor not enough privileges" });
                return;
            }

            const isThreadValid = await checkThread();
            if (!isThreadValid) {
                setState({ loading: false, valid: false, message: "Invalid Thread\nor not enough privileges" });
                return;
            }

            if (orgId) dispatch(setCurrentOrganizationId({ id: orgId, setCollection: false }));
            if (collectionId) dispatch(setCurrentCollectionId(collectionId));
            setState({ loading: false, valid: true, message: "" });
        };

        check();
    }, [collectionId, dispatch, fetchCollections, fetchOrganizations, fetchThreads, orgId, threadId]);

    return state;
}

//********************************************
export function sleep(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function objectToFormData<T>(input: T, ...exclude: Extract<keyof T, string>[]): FormData {
    const formData = new FormData();
    for (const k in input) {
        if (exclude.includes(k)) continue;
        formData.append(k, input[k] as string);
    }
    return formData;
}

export async function imageToBase64(image: Blob) {
    const promise = new Promise<string>((resolve, reject) => {
        if (!image.type.startsWith("image/")) reject("Not valid image");
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onloadend = () => {
            const base64 = reader.result as string;
            resolve(base64);
        };
    });
    return promise;
}

export function stringToHash(string: string) {
    let hash = 0;

    if (string.length === 0) return hash;

    for (let i = 0; i < string.length; i++) {
        const ch = string.charCodeAt(i);
        hash = (hash << 5) - hash + ch;
        hash = hash & hash;
    }

    return hash;
}

export function generateId() {
    return Math.random().toString(36).substring(2, 9);
}

export function getNameAndExtension(filename: string): { name: string; extension: string } {
    const idx = filename.lastIndexOf(".");
    return {
        name: filename.substring(0, idx),
        extension: filename.substring(idx + 1)
    };
}

export const nameof = <T>(name: Extract<keyof T, string>): string => name;

export function publicResource(file: string): string {
    return process.env.PUBLIC_URL + "/" + file;
}

export const BudgetTypes = {
    adv_query: {
        description: "Reasoning queries",
        unit: ""
    },
    base_query: {
        description: "Simplified reasoning queries",
        unit: ""
    },
    collections: {
        description: "Collections",
        unit: ""
    },
    embedding_kb: {
        description: "Embedding computation",
        unit: "MB"
    },
    storage_kb: {
        description: "Storage",
        unit: "MB"
    },
    stt_seconds: {
        description: "Speech-to-Text",
        unit: "seconds"
    },
    tts_characters: {
        description: "Text-to-Speech",
        unit: "characters"
    }
};

export function __build__parseBooleanEnvs(key: string, defaultValue: boolean) {
    const value = process.env[key];

    if (value === undefined) return defaultValue;

    const _checkingValue = value.toLowerCase().trim();
    return _checkingValue === "true" || _checkingValue === "1";
}

export function range(count: number, startFrom = 0) {
    return [...Array(count).keys()].map((i) => i + startFrom);
}

export function characterRange(startChar: string, count: number) {
    return [...String.fromCharCode(...range(count, startChar.charCodeAt(0)))];
}

/*
function characterRange(startChar: string, endChar: string) {
    return String.fromCharCode(...range(endChar.charCodeAt(0) - startChar.charCodeAt(0), startChar.charCodeAt(0)));
}
*/
