import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from './state/store';

const AppDistributorsDirectout = lazy(() => import('./AppDistributorsDirectout'));
const AppAskDirectout = lazy(() => import('./AppAskDirectout'));
const SophiaApp = lazy(() => import('./apps/SophiaApp.jsx'));
const PersonaApp = lazy(() => import('./apps/PersonaApp.jsx'));

const AppProvider = () => {
    switch (process.env.REACT_APP_CONFIG) {
        case 'ask_directout': return <AppAskDirectout />;
        case 'distributors_directout': return <AppDistributorsDirectout />;
        case 'cucinelli_sophia': return <SophiaApp />;
        default: return <PersonaApp />;
    }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <Suspense fallback={<></>}>
        <Provider store={store}>
            <AppProvider />
        </Provider>
      </Suspense>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
